import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useTimeout } from '@hozana/hooks/useTimeout'
import { useTranslate } from '@hozana/intl/useTranslate'
import type { TResponsiveProps } from '@hozana/styling/responsive'
import { QA } from '@hozana/tests/constants'
import { Sentry } from '@hozana/tracking/sentry'

import { RoundButton } from 'elements/button/RoundButton'
import { FLASH_ICONS } from 'elements/constants'
import { BorderedDiv, TBorderedDivProps } from 'elements/layout/BorderedDiv'
import { Cell } from 'elements/layout/Cell'
import { Row } from 'elements/layout/Row'
import { Icon } from 'elements/media/Icon'
import { SelfDestroyingDiv } from 'elements/motion/SelfDestroyingDiv'
import { P } from 'elements/text/P'
import { Text } from 'elements/text/Text'

import { ICON } from 'config/icons'

import type { TFlashMessageState } from './types'

const responsiveProps = { fontSize: 'font-size' } as const

const StyledBorderedDiv = styled(BorderedDiv)`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
`

type TFlashProps = {
  destroy: VoidFunction
  onClose?: VoidFunction
} & TFlashMessageState &
  TBorderedDivProps &
  TResponsiveProps<typeof responsiveProps>

export const Flash: React.FC<TFlashProps> = ({
  status,
  message: rawMessage,
  subMessage,
  destroy,
  onClick,
  fontSize,
  align,
  icon,
  color,
  ...otherProps
}) => {
  const { t } = useTranslation()
  const message = rawMessage === 'Failed to fetch' ? 'common:common.error.api.default' : rawMessage
  const translatedMessage = useTranslate(status === 'error' && !message.includes(':') ? `common:${message}` : message)
  const unknownTranslation = translatedMessage === message && status !== 'debug'

  const [destroying, setDestroying] = useState(false)

  useTimeout(
    () => setDestroying(true),
    // Wait longer for error or warning messages
    ['error', 'warning'].includes(status) ? 15000 : 6000,
  )
  useEffect(() => {
    if (unknownTranslation) {
      if (status === 'error') {
        Sentry.captureMessage(`Untranslated API error : ${message}`, 'warning', { message, subMessage })
      } else {
        Sentry.captureMessage(`Untranslated flash message : ${message}`, 'info', { message, subMessage })
        destroy()
      }
    }
  }, [destroy, message, status, subMessage, unknownTranslation])

  // If the translation key could not be found but this is not an error, don't display anything
  if (unknownTranslation && status !== 'error') return null

  return (
    <SelfDestroyingDiv triggered={destroying} animationTime={500} onFinish={destroy} m={'10px'}>
      <StyledBorderedDiv
        data-testid={QA.COMMON.FLASH_MESSAGE}
        value={status}
        style={{ display: 'block' }}
        noBorder
        p={{ xs: '10px', lg: '20px' }}
        onClick={onClick}
        align={align}
        shadow={true}
        {...otherProps}
      >
        <Row gutter="10px">
          <Cell style={{ display: 'flex', alignItems: 'center' }}>
            <Text fontSize={fontSize}>
              <Icon
                size={{ xs: '1.5em', lg: '2em' }}
                name={icon || FLASH_ICONS[status].icon}
                color={color || FLASH_ICONS[status].color}
              />
            </Text>
          </Cell>
          <Cell flex="1" m="auto 0">
            <P fontSize={{ xs: '1em', lg: '1em' }}>
              {status === 'debug' ? message : unknownTranslation ? t('common:common.error.unknown') : translatedMessage}
            </P>
            {subMessage && <P fontSize={{ xs: '1', lg: '1em' }}>{status === 'debug' ? subMessage : t(subMessage)}</P>}
          </Cell>
          <RoundButton
            onClick={() => setDestroying(true)}
            icon={ICON.CLOSE}
            size={fontSize}
            hoverColor={FLASH_ICONS[status].color}
            label="trans:common:word.close"
          />
        </Row>
      </StyledBorderedDiv>
    </SelfDestroyingDiv>
  )
}
