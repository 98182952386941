/*
 * WARNING : Also add the popin in the POPINS enum in the ./constants.ts file !!
 */
import dynamic from 'next/dynamic'

export const ConfirmationPopin = dynamic(() => import('app/popins/ConfirmationPopin').then((m) => m.ConfirmationPopin))
export const InputPopin = dynamic(() => import('app/popins/InputPopin').then((m) => m.InputPopin))
export const LinuxDevPopin = dynamic(() => import('app/popins/LinuxDevPopin').then((m) => m.LinuxDevPopin))
export const PrayerPromptPopin = dynamic(() => import('app/popins/PrayerPromptPopin').then((m) => m.PrayerPromptPopin))
export const ReportAbusePopin = dynamic(() => import('app/popins/ReportAbusePopin').then((m) => m.ReportAbusePopin))
export const PushRequestPopin = dynamic(() => import('app/popins/PushRequestPopin').then((m) => m.PushRequestPopin))
export const PushBlockedPopin = dynamic(() => import('app/popins/PushBlockedPopin').then((m) => m.PushBlockedPopin))
export const AppPromptPopin = dynamic(() => import('app/popins/AppPromptPopin').then((m) => m.AppPromptPopin))
export const RatePromptPopin = dynamic(() => import('app/popins/RatePromptPopin').then((m) => m.RatePromptPopin))

export const ConnectPopin = dynamic(() => import('modules/auth/popins/ConnectPopin').then((m) => m.ConnectPopin))
export const LostPwdPopin = dynamic(() => import('modules/auth/popins/LostPwdPopin').then((m) => m.LostPwdPopin))
export const DoubleFaPopin = dynamic(() => import('modules/auth/popins/DoubleFaPopin').then((m) => m.DoubleFaPopin))

export const FacebookConfirmPopin = dynamic(() =>
  import('modules/auth/popins/FacebookConfirmPopin').then((m) => m.FacebookConfirmPopin),
)
export const CommunityNotificationsPopin = dynamic(() =>
  import('modules/community/popins/CommunityNotificationsPopin').then((m) => m.CommunityNotificationsPopin),
)
export const CommunityLeavePopin = dynamic(() =>
  import('modules/community/popins/CommunityLeavePopin').then((m) => m.CommunityLeavePopin),
)
export const CommunityMembersListPopin = dynamic(() =>
  import('modules/community/popins/CommunityMembersListPopin').then((m) => m.CommunityMembersListPopin),
)

export const CommunityCommentsListPopin = dynamic(() =>
  import('modules/community/popins/CommunityCommentsListPopin').then((m) => m.CommunityCommentsListPopin),
)
export const SubmittedCommunityPopin = dynamic(() =>
  import('modules/community/popins/SubmittedCommunityPopin').then((m) => m.SubmittedCommunityPopin),
)
export const CommunitiesPropositionsPopin = dynamic(() =>
  import('modules/community/popins/CommunitiesPropositionsPopin').then((m) => m.CommunitiesPropositionsPopin),
)

export const BroadcastIntentionWidgetPopin = dynamic(() =>
  import('modules/intention/popins/BroadcastIntentionWidgetPopin').then((m) => m.BroadcastIntentionWidgetPopin),
)

export const CommunityPrayPopin = dynamic(() =>
  import('modules/pray/popins/CommunityPrayPopin').then((m) => m.CommunityPrayPopin),
)
export const UserPrayPopin = dynamic(() => import('modules/pray/popins/UserPrayPopin').then((m) => m.UserPrayPopin))

export const InvitePopin = dynamic(() => import('modules/share/popins/InvitePopin').then((m) => m.InvitePopin))
export const InviteEmailPopin = dynamic(() =>
  import('modules/share/popins/InviteEmailPopin').then((m) => m.InviteEmailPopin),
)
export const WidgetPopin = dynamic(() =>
  import('modules/share/popins/WidgetPopin/WidgetPopin').then((m) => m.WidgetPopin),
)

export const PresubscriberDeletedPopin = dynamic(() =>
  import('modules/user/popins/PresubscriberDeletedPopin').then((m) => m.PresubscriberDeletedPopin),
)
export const AvatarsListPopin = dynamic(() =>
  import('modules/user/popins/AvatarsListPopin').then((m) => m.AvatarsListPopin),
)
export const AvatarPopin = dynamic(() => import('modules/user/popins/AvatarPopin').then((m) => m.AvatarPopin))
export const MailDeliveryIssuePromptPopin = dynamic(() =>
  import('modules/user/popins/MailDeliveryIssuePromptPopin').then((m) => m.MailDeliveryIssuePromptPopin),
)
