import { useEffect, useState } from 'react'
import { useIsomorphicLayoutEffect } from 'usehooks-ts'

type UseLockedBodyOutput = [boolean, (locked: boolean) => void]

// We originally locked the body. But it had the effect to make the body scroll on top of the page.
// To tacckle it, we now lock the HTML instead.
// This however seems to be a discouraged practice.
// https://stackoverflow.com/questions/15019510/setting-overflow-y-hidden-causes-the-page-to-jump-to-the-top-in-firefox
export function useLockedHtml(initialLocked = false): UseLockedBodyOutput {
  const [locked, setLocked] = useState(initialLocked)

  // Do the side effect before render
  useIsomorphicLayoutEffect(() => {
    if (!locked) {
      return undefined
    }

    const originalOverflow = document.getElementsByTagName('html')[0].style.overflow

    // Lock html scroll
    document.getElementsByTagName('html')[0].style.overflow = 'hidden'

    return () => {
      document.getElementsByTagName('html')[0].style.overflow = originalOverflow
    }
  }, [locked])

  // Update state if initialValue changes
  useEffect(() => {
    setLocked(initialLocked)
  }, [initialLocked])

  return [locked, setLocked]
}
