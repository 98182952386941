import React from 'react'
import styled from 'styled-components'

import { useIsHydrated } from '@hozana/hooks/useIsHydrated'
import { useDispatch, useSelector } from '@hozana/redux/hooks'

import { FixedDiv } from 'elements/layout/FixedDiv'

import { destroyFlash } from 'app/actions'
import { selectFlashMessages } from 'app/selectors'

import { Flash } from './Flash'

const StyledFixedDiv = styled(FixedDiv)`
  pointer-events: none;
`

export const FlashManager: React.FC = () => {
  const dispatch = useDispatch()
  const flashMessages = useSelector(selectFlashMessages)
  const isHydrated = useIsHydrated()

  // Don't render on SSR as it breaks the hydration
  return !isHydrated ? null : (
    <StyledFixedDiv bottom right w="100%" zIndex="flashMessage" maxW={{ sm: '400px' }} noPrint>
      {flashMessages.map((flashMessage, i) => (
        <Flash
          key={i}
          {...flashMessage}
          destroy={() => setTimeout(() => dispatch(destroyFlash(flashMessage.id)), 100)}
        />
      ))}
    </StyledFixedDiv>
  )
}
