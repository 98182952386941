import React from 'react'
import styled, { css, keyframes } from 'styled-components'

import { useTimeout } from '@hozana/hooks/useTimeout'
import { TResponsiveProps } from '@hozana/styling/responsive'

import { Div } from 'elements/layout/Div'

type TLeftOrRight = 'left' | 'right'

const animateIn = (origin: TLeftOrRight) => keyframes`
  from {
    transform: translate(${origin === 'right' ? '110%' : '-110%'}, 0);
  }
  to {
    transform: translate(0, 0);
  }
`

const animateOut = (direction: TLeftOrRight) => keyframes`
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(${direction === 'right' ? '110%' : '-110%'}, 0)  
  }
`
type TAnimatedDivProps = {
  /** Should the div be destroyed ? */
  triggered?: boolean
  /** Animation time in ms */
  animationTime: number
  /** Direction of the animation */
  direction?: TLeftOrRight
  /** Where the animation should start */
  origin?: TLeftOrRight
  shadow?: boolean
}
const AnimatedDiv = styled(Div)<TAnimatedDivProps>`
  box-shadow: ${(props) => (props.shadow ? '1px 1px 3px rgba(0, 0, 0, 0.5)' : 'none')};
  border-radius: 5px;
  pointer-events: auto;
  animation: ${(props) =>
    props.triggered
      ? css`
          ${animateOut(props.direction)} ${props.animationTime}ms forwards
        `
      : css`
          ${animateIn(props.origin)} ${props.animationTime}ms forwards
        `};
  bottom: 0;
`
const responsiveProps = { m: 'margin', w: 'width' } as const

type TSelfDestroyingDivProps = {
  onFinish: VoidFunction // Callback when animation is finished
} & TAnimatedDivProps &
  TResponsiveProps<typeof responsiveProps>

export const SelfDestroyingDiv: React.FC<TSelfDestroyingDivProps> = ({
  triggered,
  animationTime,
  onFinish,
  children,
  shadow = false,
  direction = 'right',
  origin = 'right',
  ...otherProps
}) => {
  useTimeout(onFinish, animationTime, !triggered)
  return (
    <AnimatedDiv
      triggered={triggered}
      animationTime={animationTime}
      direction={direction}
      shadow={shadow}
      origin={origin}
      transition
      {...otherProps}
    >
      {children}
    </AnimatedDiv>
  )
}
