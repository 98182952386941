import React, { ComponentType, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useEventListener } from '@hozana/hooks/useEventListener'
import { useLockedHtml } from '@hozana/hooks/useLockedHtml'
import { usePrevious } from '@hozana/hooks/usePrevious'
import { useDispatch, useSelector } from '@hozana/redux/hooks'
import { Sentry } from '@hozana/tracking/sentry'
import { KEYS } from '@hozana/utils/constants'

import { Div } from 'elements/layout/Div'

import { closePopin as closePopinAction } from 'app/actions'
import { selectPopin } from 'app/selectors'

import * as allPopins from './popins'
import type { TGenericPopinProps, TPopinState } from './types'

const initPopin: TPopinState = {
  isOpen: false,
  name: null,
  options: {},
}

/**
 * ### PopinsManager ###
 */
export const PopinsManager: React.FC = () => {
  const dispatch = useDispatch()
  const {
    i18n: { language },
  } = useTranslation()
  const [, setLocked] = useLockedHtml(false)

  const popin = useSelector(selectPopin)
  const prevPopin = usePrevious(popin) || initPopin

  const closePopin = useCallback(() => dispatch(closePopinAction(popin.name)), [dispatch, popin.name])

  // Event listeners to close popin
  useEventListener(
    'keydown',
    (e) => {
      if (KEYS.ESC.includes(e.key || e.keyCode)) {
        closePopin()
      }
    },
    __CLIENT__ ? window.document : undefined,
    !__CLIENT__ || !popin.isOpen,
  )
  useEventListener('popstate', closePopin, undefined, !__CLIENT__ || !popin.isOpen)

  // Hide scroll under popin
  useEffect(() => {
    if (popin.isOpen && __CLIENT__) {
      setLocked(true)
    } else {
      setLocked(false)
    }
  }, [popin.isOpen, setLocked])

  // Tracking
  useEffect(() => {
    if ((!prevPopin.isOpen && popin.isOpen) || prevPopin.name !== popin.name) {
      Sentry.addBreadcrumb({
        category: 'popins',
        message: `Opening ${popin.name} popin`,
        level: 'info',
        data: popin.options,
      })
    }
  }, [language, popin, prevPopin])

  if (!popin.isOpen) return null
  // eslint-disable-next-line import/namespace
  const Popin =
    popin.name !== null && popin.name !== undefined
      ? allPopins[popin.name]
      : (Div as ComponentType<TGenericPopinProps<TNoMoreProps>>)
  const onClose = popin.options?.noEscape ? null : closePopin

  return <Popin onClose={onClose} options={popin.options} />
}
